<template lang="pug">
  div.demo
    div.container-sm.demo__wrap
      h1 {{ $t('nav.demo') }}
      p {{ $t('demo.welcomeText') }}
      p {{ $t('demo.versionText') }}
      p {{ $t('demo.accessText') }}
      div.demo__try
        h3 {{ $t('demo.credentials') }}
        form
          label
            span {{ $t('demo.login') }}
            input(
              type="text"
              value="demo"
              v-model="messageLogin"
              ref="messageLogin"
            )
            button(@click.stop.prevent="copyTextLogin()")
              img(
                :src="Copy"
                alt="copy"
              )
          label
            span {{ $t('demo.password') }}
            input(
              type="text"
              value="epdemo12"
              v-model="messagePass"
              ref="messagePass"
            )
            button(@click.stop.prevent="copyTextPass()")
              img(
                :src="Copy"
                alt="copy"
              )
          a(
            href="https://demo.easypon.in/login?username=demo&password=epdemo12"
            target="_blank"
          ) {{ $t('demo.tryDemo') }}
      p {{ $t('demo.exploreText') }}
      p(v-html="$t('demo.experienceText', { link: `<a href='https://cabinet.easypon.in/registration' target='_blank'>https://cabinet.easypon.in/registration</a>` })")
      p {{ $t('demo.confidentText') }}
      //div.demo__quest
      //  h3 {{ $t('contact.question') }}
      //  form
      //    label
      //      span {{ $t('demo.email') }}
      //      input(type="email" placeholder="you@company.com" required)
      //    label
      //      span {{ $t('demo.message') }}
      //      textarea
      //    input(type="submit" :value="$t('contact.sendMessage')")
</template>

<script>
import Copy from '@/assets/images/copy.svg'

export default {
  name: 'DemoPage',
  metaInfo () {
    return this.$metaDefault()
  },
  data () {
    return {
      Copy,
      messageLogin: 'demo',
      messagePass: 'epdemo12'
    }
  },
  methods: {
    copyTextLogin () {
      const element = this.$refs.messageLogin
      element.select()
      element.setSelectionRange(0, 99999)
      document.execCommand('copy')
    },
    copyTextPass () {
      const element = this.$refs.messagePass
      element.select()
      element.setSelectionRange(0, 99999)
      document.execCommand('copy')
    }
  }
}
</script>
